<template>
    <template v-if="!isLoadingBil">
        <section class="aa-contenedor-basico fade-in">
            <div class="aa-contenedor-basico-portada">
                <img src="../../../assets/img/fotos/fray.png" alt="IES Fray Bartolomé de las Casas" class="portada" v-on:load="onLoaded" v-show="loaded" />
                <div style="width:100%; height: 327px" v-show="!loaded">
                    <LoadingVue />
                </div>
            </div>

            <div class="text-start aa-contenedor-basico-info text-wrap mt-2" v-html="bilingue.escuela"></div>

        </section>
    </template>
    <template v-else>
        <PrensaSk />
    </template>
</template>

<script>
import LoadingVue from '../components/Loading.vue'

import useBilin from '../composables/useBilin'
import PrensaSk from '../components/skeleton/PrensaSk.vue'


export default {
    components: { LoadingVue, PrensaSk },
    setup() {
        const { isLoadingBil, loaded, bilingue, getBasico } = useBilin()
        getBasico()
        return {
            isLoadingBil,
            bilingue,
            loaded,
            onLoaded: () => loaded.value = true,
        }
    }
}
</script>

<style lang="sass" scoped>
.aa-bilingue
    $root:&
    
    display: flex
    flex-direction: column
    flex-wrap: wrap
    margin-top: 96px
    &-portada
        display: flex
        flex-direction: column
        .portada-bilingue
            order:1
            width: 100%
            margin-bottom: 0px

    .contenedor
        .img-contenedor
            width: 100%
            height: 118px
            img
                width: 100%
                object-fit: cover
                height: 100%
        h4
            width: 100%
            margin-block-start: 0.3rem

.swiper-slide
    width: 200px


</style>